import React, {useEffect, useState } from 'react';
import { useLocation, } from 'react-router-dom'; 
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import NotyMasowa from './NotyMasowa';
import Loader from '../Loader';
import NotyTHead from './NotyTHead';
import NotyTBody from './NotyTBody';
import NotyHistoria from './NotyHistoria';
import { obliczDatyNoty } from '../../functions/dataFormat';
import { fetchNoty } from '../../Redux/Actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'

const Noty = () => {

    const queryParams = new URLSearchParams(window.location.search)
    const id_wspolnoty = queryParams.get("idw");

    const location = useLocation();
    const { state } = location;

    const tablicaCzynsze = state?.from?.tablicaCzynsze;

    const stawki = useSelector( state => state?.stawki?.data);
    const wspolnoty = useSelector( state  => state?.wspolnoty?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const grupy = useSelector( state => state?.grupy?.data);
    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);
    const planowaneZuzycie = useSelector( state => state?.planowaneZuzycie?.data);
    const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data);
    const stawkiJednorazoweInd = useSelector( state => state?.stawkiJednorazoweInd?.data);
    const stawkiInd = useSelector( state => state?.stawkiInd?.data);
    const lokale = useSelector(state => state?.lokale?.data);
    const ulice = useSelector(state => state?.ulice?.data);

    const noty = useSelector( state  => state?.noty?.data);

    console.log(noty?.filter( n => n?.id_wspolnoty == 8))

    const [activeWindow, setActiveWindow] = useState("nowe")
    const [tablica, setTablica] = useState([]);

    const [wspolnota, setWspolnota] = useState([]);
    const [udzialyKontaAkt_w, setUdzialyKontaAkt_w] = useState([]);

    const [loaderOn, setLoaderOn] = useState(false);

    const [activeDock, setActiveDock] = useState("");
    const [wysylkaStan, setWysylkaStan] = useState([]);

    const [noty_w, setNoty_w] = useState([]);

    const datyNoty = obliczDatyNoty();

    const dispatch = useDispatch();

    useEffect( () => dispatch(fetchNoty()),[dispatch]);
    useEffect( () => setWspolnota( wspolnoty?.find( w => w?.id == id_wspolnoty) ), [wspolnoty, id_wspolnoty]);
    useEffect( () => setUdzialyKontaAkt_w( udzialyKontaAkt?.filter( u => u?.id_wspolnoty == id_wspolnoty)), [udzialyKontaAkt, id_wspolnoty]);
    useEffect( () => setNoty_w( noty?.filter(n => n?.id_wspolnoty == wspolnota?.id)), [noty, wspolnota]);

    useEffect( () => {
        const temp = udzialyKontaAkt_w?.filter(udzial => {
            const mieszkaniec = mieszkancy?.find(m => m?.id_user == udzial?.id_user);
    
            if (mieszkaniec?.czy_nota == 1) {
              return true; // Warunek spełniony, zwracamy ten obiekt
            }
            return false; // Obiekt nie spełnia warunku
          }).map(udzial => {
            const mieszkaniec = mieszkancy?.find(m => m?.id_user == udzial?.id_user);
            const grupyNoty = tablicaCzynsze?.grupyNoty?.find( g => g?.grupa_numer == udzial?.grupa_numer && g?.id_user == udzial?.id_user)
            return {
              mieszkaniec,
              nota: udzial?.id,
              udzialyKontaAkt_u: udzial,
              grupyNoty
            };
        });
        setTablica(temp);
    },[udzialyKontaAkt_w, mieszkancy, ])

    const handleSite = site => setActiveWindow(site);
    const handleRefresh = () => dispatch(fetchNoty());

    const strona = () => {
        switch(activeWindow) {
            case "nowe":
                return (
                    <>
                        <div className='lista'>
                            <h4>
                                Noty w miesiącu {datyNoty.miesiacNaliczenia} 
                                <FontAwesomeIcon 
                                    icon={faArrowsRotate}
                                    className='pointer'
                                    style={{margin: "0 10px"}} 
                                    onClick={handleRefresh}
                                />
                            </h4>
                            
                            <table className="noty" >
                                <NotyTHead />
                                <NotyTBody
                                    grupy={grupy}
                                    lokale={lokale}
                                    ulice={ulice}
                                    wysylkaStan={wysylkaStan}
                                    tablica={tablica}
                                    setActiveDock={setActiveDock}
                                    noty={noty}
                                    grupyNoty={tablicaCzynsze?.grupyNoty}
                                />
                            </table>
                        </div>
                        <div className='preview'>
                            <NotyMasowa
                                tablicaObiektow={tablica}
                                setWysylkaStan={setWysylkaStan} 
                                wspolnota={wspolnota}   
                                tablicaCzynsze={tablicaCzynsze}
                                lokale={lokale}
                                setLoaderOn={setLoaderOn}
                                setActiveDock={setActiveDock}
                                activeDock={activeDock}
                                mieszkancy={mieszkancy}
                                noty={noty}
                            />
                        </div>
                    </>

                )   
            case "historia":
                return (
                    <NotyHistoria
                        grupy={grupy}
                        udzialyKontaAkt={udzialyKontaAkt}
                        lokale={lokale}
                        ulice={ulice}
                        tablica={tablica}
                        noty_w={noty_w}
                        grupyNoty={tablicaCzynsze?.grupyNoty}
                        mieszkancy={mieszkancy}
                    />
                )   
        }
    }

    return (
        <div className='mieszkancy'>
            <h1 className="nazwa">
                Noty obciążeniowe 
            </h1>
            <h3>
                { wspolnota?.name }
            </h3>
            <div className='buttons'>   
                <NavLink to="/wspolnoty"> 
                    <button>
                        Wróć do listy WM
                    </button>
                </NavLink>
                <button
                    onClick={()=>handleSite("nowe")}
                >
                    Generowanie i wysyłka
                </button>
                <button
                    onClick={()=>handleSite("historia")}
                >
                    Historia
                </button>
            </div>
            <div className='notyContainer'>
                {strona()}
            </div>

            <Loader
                stan={loaderOn}
            />
        </div>
    )
}

export default Noty;