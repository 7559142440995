import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { obliczDatyNoty } from '../../functions/dataFormat';
import { findMieszkanieId, adresPro } from '../../functions/ulice';
import { fetchNoty } from '../../Redux/Actions';
import { AuthContext} from '../../Context/AuthProvider';
import { dataCzas } from '../../functions/dataFormat';

const NotyTBody = ( props ) => {

    const { grupy, lokale, ulice, wysylkaStan, tablica, setActiveDock, noty } = props;

    const dispatch = useDispatch();
    
    const {user} = useContext(AuthContext);

    const [lista, setLista] = useState([]);

    const pobierz = async fileName => {
      console.log(fileName)
      try {
          const response = await axios.post( 
              '/api/noty/pobierz',
              { fileName: fileName }, 
              { responseType: 'blob' })
          
          // if (response.statusText !== "OK") {
          //   throw new Error('Błąd podczas pobierania pliku PDF');
          // }
          // console.log(response)
    
          const url = window.URL.createObjectURL(new Blob([response.data]));
    
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); // Nazwa pliku do pobrania
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } catch (error) {
          alert(`Plik "${fileName}" nie istnieje.`)
        }
  }    
    
    const handleSendPdf = async (email, filename, msc, notaNr ) => {
        try {
            const response = await axios.post('/api/noty/send-mail', {
              email,
              filename,
              msc, 
              notaNr
            });
        
            if (response.status === 200) {
              console.log('E-mail został wysłany pomyślnie!');
              addToHistory(email, filename);
            }
          } catch (error) {
            console.error('Błąd podczas wysyłania e-maila:', error);
          }
        
    }

    const addToHistory = async (email, filename) => {

        try {
          // Wysłanie PDF na backend
          const response = await axios.post('/api/noty/mail-history', {email, user, filename});
          console.log(response)
          if (response.status === 200) {
            dispatch(fetchNoty());
    
          } else {
            alert('Wystąpił problem z wysyłaniem danych.');
          }
        } catch (error) {
          console.error('Błąd podczas wysyłania danych.', error);
        }
    }

    useEffect( () => {
        const temp = tablica?.map ( (element, i) => {
          
          const mieszkanieId = findMieszkanieId(element.udzialyKontaAkt_u, grupy, lokale);
          const mieszkanie = adresPro(mieszkanieId, lokale, ulice);
          const stan = wysylkaStan[`stan_${element.nota}`] 
          const msc = obliczDatyNoty()?.miesiacNaliczenia;
          const fileName = `Nota obciążeniowa - ${ element?.mieszkaniec?.imie_nazwisko } - ${element?.grupyNoty?.notaNr} - ${ msc }.pdf`
          const notaHist = noty?.find( n => n?.nazwa_pliku == fileName);

          const ifSent = notaHist?.mail_adres ? true : false;

          const email = () => {
            if (notaHist && ifSent) {
              return (
                `wysłany ${dataCzas(notaHist?.mail_dataczas)}`
              )
            } else if (notaHist && !ifSent) {
              return (
                <FontAwesomeIcon
                    icon={faPaperPlane}
                    className='pointer'
                    onClick={()=>handleSendPdf(element?.mieszkaniec?.email,fileName, msc, element?.grupyNoty?.notaNr)}
                />
              )
            } else if (!notaHist) {
              return (``)
            }
          }

          return (
              <tr key={i}>
                  <td>
                      {`${element.mieszkaniec?.imie_nazwisko}` }
                  </td>
                  <td>
                      {`${mieszkanie} (grupa ${element.udzialyKontaAkt_u.grupa_numer})`}
                  </td>
                  <td
                      id={element?.nota}
                      
                      className='central'
                  >
                    
                      { notaHist ? ( 
                        <>
                          {fileName}
                          <FontAwesomeIcon 
                              icon={faEye} 
                              size='xs'
                              id={element?.nota}
                              onClick={()=>pobierz(fileName)}
                              className='pointer'
                          /> 
                        </>
                      ) : " (brak pliku)"
                      }
                  </td>
                  <td>
                      { stan }
                  </td>
                  <td className='central'>
                      { email() }
                  </td>
              </tr>
          )
      });
      setLista(temp);
    }, [tablica, noty]);

    

    return (
        <tbody>
            {lista}
        </tbody>
    )
}

export default NotyTBody;