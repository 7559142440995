import React, {useEffect, useState } from 'react';

import NotyHistoriaTHead from './NotyHistoriaTHead';
import NotyHistoriaTBody from './NotyHistoriaTBody';


const NotyHistoria = props => {

    const { grupy, udzialyKontaAkt, lokale, ulice, tablica, noty_w, grupyNoty, mieszkancy } = props;

    const [historiaMsc, setHistoriaMsc] = useState("");
    const [listaMsc, setListaMsc] = useState([]);
    const [notyMsc, setNotyMsc] = useState([]);

    useEffect( () => {
        const znajdzUnikatoweMsc = (noty_w) => {
          // Tworzymy zbiór, aby wyeliminować duplikaty
          const unikalneMsc = [...new Set(noty_w.map(nota => nota.msc))];
          // Sortujemy alfabetycznie
          return unikalneMsc.sort();        
        };
        setListaMsc(znajdzUnikatoweMsc(noty_w));
    },[noty_w]);

    useEffect( () => setHistoriaMsc( listaMsc[listaMsc?.length -1]), [listaMsc]);

    useEffect( () => setNotyMsc( noty_w?.filter( n => n?.msc == historiaMsc ) ), [noty_w, historiaMsc]);

    const lista = listaMsc?.map( (msc, i) => (
        <option
            key={i}
            value={msc}
        >
            {msc}
        </option>
    ));

    const handleMsc = e => setHistoriaMsc( e.target.value );

    return (
        <div className='lista historia'>
            <h4>
                Historia not
            </h4>
            <label
                htmlFor='lista'
            >
                Wybierz miesiąc:
            </label>
            <select
                id='lista'
                value={historiaMsc}
                onChange={handleMsc}
                style={{margin: "0 0 15px 15px"}}
            >
                {lista}
            </select>
            <table className="noty" >
                <NotyHistoriaTHead />
                <NotyHistoriaTBody
                    grupy={grupy}
                    udzialyKontaAkt={udzialyKontaAkt}
                    lokale={lokale}
                    ulice={ulice}
                    tablica={tablica}
                    notyMsc={notyMsc}
                    grupyNoty={grupyNoty}
                    mieszkancy={mieszkancy}
                />
            </table>
        </div>
    )
}

export default NotyHistoria;