import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { useDispatch, useStore } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { obliczDatyNoty } from '../../functions/dataFormat';
import { findMieszkanieId, adresPro } from '../../functions/ulice';
import { fetchNoty } from '../../Redux/Actions';
import { AuthContext} from '../../Context/AuthProvider';
import { dataCzas } from '../../functions/dataFormat';

const NotyHistoriaTBody = ( props ) => {

    const { grupy, udzialyKontaAkt, lokale, ulice, tablica, notyMsc, mieszkancy } = props;

    const dispatch = useDispatch();
    
    const {user} = useContext(AuthContext);

    const [notyUniq, setNotyUniq] = useState([]);

    useEffect( ( ) => {

      const znajdzNajnowszeNoty = (notyMsc) => {
        // Tworzymy mapę, aby przechowywać najnowsze noty dla par id_user-grupa_numer
        const najnowszeNotyMap = new Map();
      
        notyMsc.forEach((nota) => {
          const key = `${nota.id_user}-${nota.grupa_numer}`;
          const existingNota = najnowszeNotyMap.get(key);
      
          // Jeśli klucz już istnieje, porównujemy daty
          if (!existingNota || new Date(nota.data_utworzenia) > new Date(existingNota.data_utworzenia)) {
            najnowszeNotyMap.set(key, nota);
          }
        });
      
        // Konwertujemy mapę na tablicę wartości
        return Array.from(najnowszeNotyMap.values());
      };

      setNotyUniq(znajdzNajnowszeNoty(notyMsc));

    },[notyMsc])

    // console.log(notyMsc?.filter( n => n?.grupa_numer == 13));
    // console.log(notyUniq?.filter( n => n?.grupa_numer == 13));

    const pobierz = async fileName => {
        console.log(fileName)
        try {
            const response = await axios.post( 
                '/api/noty/pobierz',
                { fileName: fileName }, 
                { responseType: 'blob' })
            
            // if (response.statusText !== "OK") {
            //   throw new Error('Błąd podczas pobierania pliku PDF');
            // }
            // console.log(response)
      
            const url = window.URL.createObjectURL(new Blob([response.data]));
      
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Nazwa pliku do pobrania
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          } catch (error) {
            alert(`Plik "${fileName}" nie istnieje.`)
          }
    }   
    
    const handleSendPdf = async (email, filename, msc, notaNr ) => {
        try {
            const response = await axios.post('/api/noty/send-mail', {
              email,
              filename,
              msc, 
              notaNr
            });
        
            if (response.status === 200) {
              console.log('E-mail został wysłany pomyślnie!');
              addToHistory(email, filename);
            }
          } catch (error) {
            console.error('Błąd podczas wysyłania e-maila:', error);
          }
    }

    const addToHistory = async (email, filename) => {

        try {
          // Wysłanie PDF na backend
          const response = await axios.post('/api/noty/mail-history', {email, user, filename});
          console.log(response)
          if (response.status === 200) {
            dispatch(fetchNoty());
    
          } else {
            alert('Wystąpił problem z wysyłaniem danych.');
          }
        } catch (error) {
          console.error('Błąd podczas wysyłania danych.', error);
        }
    }

    const lista = notyUniq?.map( (element, i) => {

      const udzial = udzialyKontaAkt?.find( u => u?.id_user == element?.id_user && u.grupa_numer == element?.grupa_numer);

      const mieszkanieId = findMieszkanieId(udzial,grupy,lokale);      

      const mieszkanie = adresPro(mieszkanieId, lokale, ulice)
      const msc = element?.msc;
      const imieNazwisko = mieszkancy?.find( m => m?.id_user == element?.id_user)?.imie_nazwisko;
      
      if (element?.mail_dataczas !== null) {

        return (
          <tr key={i}>
            <td>
              {element.id}
            </td>
            <td>
              {`${imieNazwisko}` }
            </td>
            <td>
                {`${mieszkanie} (g. ${element?.grupa_numer})`}
            </td>
            <td>
              {msc}
            </td>
            <td
              id={element?.nota}
              className='central'
            >
              {
                <FontAwesomeIcon 
                    icon={faEye} 
                    size='xs'
                    id={element?.nota}
                    onClick={()=>pobierz(element?.nazwa_pliku)}
                    className='pointer'
                />
              }
            </td>
            <td>
              {element?.nazwa_pliku}
            </td>
            <td className='central'>
              { `wysłany ${dataCzas(element?.mail_dataczas)}` }
            </td>
          </tr>
        )

      }


    });

    // const lista2 = tablica?.map ( (element, i) => {

    //     const mieszkanieId = findMieszkanieId(element.udzialyKontaAkt_u, grupy, lokale);
    //     const mieszkanie = adresPro(mieszkanieId, lokale, ulice);
    //     const msc = obliczDatyNoty()?.miesiacNaliczenia;
    //     const fileName = `Nota obciążeniowa - ${ element?.mieszkaniec?.imie_nazwisko } - ${element?.grupyNoty?.notaNr} - ${ msc }.pdf`
    //     const notaHist = notyMsc?.find( n => n?.nazwa_pliku == fileName);

    //     const ifSent = notaHist?.mail_adres ? true : false;

    //     const email = () => {
    //       if (notaHist && ifSent) {
    //         return (
    //           `wysłany }`
    //         )
    //       } else if (notaHist && !ifSent) {
    //         return (
    //           <FontAwesomeIcon
    //               icon={faPaperPlane}
    //               className='pointer'
    //               onClick={()=>handleSendPdf(element?.mieszkaniec?.email,fileName, msc, element?.grupyNoty?.notaNr)}
    //           />
    //         )
    //       } else if (!notaHist) {
    //         return (``)
    //       }
    //     }

    //     return (
    //         <tr key={i}>
    //             <td>
    //                 {`${element.mieszkaniec?.imie_nazwisko}` }
    //             </td>
    //             <td>
    //                 {`${mieszkanie} (g. ${element.udzialyKontaAkt_u.grupa_numer})`}
    //             </td>
    //             <td>
    //               {msc}
    //             </td>
    //             <td
    //                 id={element?.nota}
                    
    //                 className='central'
    //             >
    //                 { notaHist ? (
    //                     <FontAwesomeIcon 
    //                         icon={faEye} 
    //                         size='xs'
    //                         id={element?.nota}
    //                         onClick={()=>pobierz(fileName)}
    //                         className='pointer'
    //                     /> 
    //                 ) : "brak pliku"
    //                 }
    //             </td>
    //             <td className='central'>
    //                 { email() }
    //             </td>
    //         </tr>
    //     )
    // })

    return (
        <tbody>
            {lista}
        </tbody>
    )
}

export default NotyHistoriaTBody;