import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../scss/dodajGrupe.css';
import { 
    fetchWspolnoty, 
    fetchMieszkancy,
    fetchMieszkancyAll,
    fetchUdzialyKonta,
    fetchUdzialyKontaAkt, 
    fetchGrupy,
    fetchGrupyAkt,
    fetchUlice,
    fetchLokale,
    fetchPrzelewy,
  } from '../../Redux/Actions.js'; 
  import MieszkancyLista from './MieszkancyLista';
import MieszkancyDane from './MieszkancyDane.js';
import MieszkancyAddForm from './MieszkancyAddForm.js';
import { NavLink } from 'react-router-dom';
import Loader from '../Loader.js';
import { getTablicaPrzelewy2 } from '../../functions/tablice.js';
import { sortByTxt } from '../../functions/utilities.js';
import { dataFormat } from '../../functions/dataFormat.js';
import { useLocation, } from 'react-router-dom';

const Mieszkancy = () => {

    const queryParams = new URLSearchParams(window.location.search)
    const id_wspolnoty = queryParams.get("idw");
    const id_user = queryParams.get("u");
    const grupa = queryParams.get("g");

    const dispatch = useDispatch();

    const [loaderOn, setLoaderOn] = useState(true);
    const [mieszkaniec, setMieszkaniec] = useState();
    const [mieszkaniecAll, setMieszkaniecAll] = useState();
    const [mieszkancy_w, setMieszkancy_w] = useState([]);
    const [mieszkancyAll_w, setMieszkancyAll_w] = useState([]);
    const [wspolnota, setWspolnota] = useState([]);
    const [udzialyKonta_w, setUdzialyKonta_w] = useState([]);
    const [grupyAkt_w, setGrupyAkt_w] = useState([]);
    const [udzialyKontaAkt_w, setUdzialyKontaAkt_w] = useState([]);
    const [tablicaPrzelewy, setTablicaPrzelewy] = useState([]);
    const [tablicaPrzelewy_w, setTablicaPrzelewy_w] = useState([]);
    const [tablicaPrzelewy_u, setTablicaPrzelewy_u] = useState([]);
    const [edit, setEdit] = useState(false);
    const [add, setAdd] = useState(false);
    const [editDataOd, setEditDataOd] = useState({id: 0, show: false, dataOd: ""});
    const [editDataDo, setEditDataDo] = useState({id: 0, show: false, dataDo: ""});

    useEffect( () => {
        if ( mieszkancy_w && mieszkancyAll_w && wspolnota && udzialyKonta_w && grupyAkt_w && udzialyKontaAkt_w)
            setLoaderOn(false)

    },[ mieszkancy_w, mieszkancyAll_w, wspolnota, udzialyKonta_w, grupyAkt_w, udzialyKontaAkt_w])
    const location = useLocation();
    const { state } = location;
    const wspolnoty = useSelector( state => state?.wspolnoty?.data);
    // const planowaneZuzycie = useSelector( state => state?.planowaneZuzycie?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data );
    const mieszkancyAll = useSelector( state => state?.mieszkancyAll?.data);
    const tablicaCzynsze = state?.from?.tablicaCzynsze;


    useEffect( () => {
        setWspolnota( wspolnoty?.find( w => w.id == id_wspolnoty) )
    },[wspolnoty, id_wspolnoty]);
    
    useEffect( () => {
        setMieszkancy_w( sortByTxt(mieszkancy?.filter( m => m.id_wspolnoty == id_wspolnoty),"imie_nazwisko") )
    },[mieszkancy, id_wspolnoty]);

    useEffect( () => {
        setMieszkancyAll_w( mieszkancyAll?.filter( m => m?.id_wspolnoty == id_wspolnoty))
    },[mieszkancyAll, id_wspolnoty])

    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const grupy = useSelector( state => state?.grupy?.data);
    const grupyAkt = useSelector( state => state?.grupyAkt?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);
    const przelewy = useSelector( state => state?.przelewy?.data );

    useEffect(()=>{
        setUdzialyKonta_w( udzialyKonta?.filter( u => u.id_wspolnoty == wspolnota?.id) );
    },[udzialyKonta, wspolnota]);
    
    useEffect(()=>{
        setUdzialyKontaAkt_w( udzialyKontaAkt?.filter( u => u.id_wspolnoty == wspolnota?.id) );
    },[udzialyKontaAkt, wspolnota]);

    useEffect( () => setGrupyAkt_w( grupyAkt?.filter( g => g.id_wspolnoty == wspolnota?.id)), [grupyAkt, wspolnota]);
    useEffect( () => setTablicaPrzelewy( getTablicaPrzelewy2(wspolnoty, udzialyKonta, przelewy)[0] ), [wspolnoty, udzialyKonta, przelewy]);
    useEffect( () => setTablicaPrzelewy_w( tablicaPrzelewy?.find( t => t?.id_wspolnoty == wspolnota?.id) ), [tablicaPrzelewy, wspolnota]);
    useEffect( () => setTablicaPrzelewy_u( tablicaPrzelewy_w?.grupy?.filter( t => t?.id_user ==  mieszkaniec?.id_user) ), [tablicaPrzelewy_w, mieszkaniec]);


    useEffect( ()=> {
        dispatch(fetchWspolnoty()); 
        dispatch(fetchMieszkancy()); 
        dispatch(fetchMieszkancyAll());
        dispatch(fetchUdzialyKonta());
        dispatch(fetchUdzialyKontaAkt());
        dispatch(fetchGrupy());
        dispatch(fetchGrupyAkt());
        dispatch(fetchUlice());
        dispatch(fetchLokale());
        dispatch(fetchPrzelewy());
    },[dispatch]);

    useEffect( () => setMieszkaniec( mieszkancy_w?.find( m => m.id_user == mieszkaniec?.id_user ) ), [mieszkancy_w, mieszkaniec]);
    useEffect( () => setMieszkaniecAll( mieszkancyAll_w?.filter( m => m.id_user == mieszkaniec?.id_user)), [mieszkancyAll_w, mieszkaniec]);

    useEffect( () => {
        if (id_user) {
            setMieszkaniec( mieszkancy_w?.find( m => m.id_user == id_user ) )
        }
    },[id_user, mieszkancy_w]);

    const handleAdd = () => {
        setAdd(true);
        setEdit(false);   
        setMieszkaniec(undefined);
    }

    const handleList = () => {
        setAdd(false);
        setEdit(false); 
        setMieszkaniec(undefined);
    }

    return (
        <div className='mieszkancy'>
            <h1 className="nazwa">
                Mieszkańcy
            </h1>
            <h3>
                { wspolnota?.name }
            </h3>
            <div className='buttons'>
                <NavLink to="/wspolnoty"> 
                    <button>
                        Wróć do listy WM
                    </button>
                </NavLink>

                <button
                    onClick={handleAdd}
                >
                    Dodaj mieszkańca
                </button>
                <button
                    onClick={handleList}
                >
                    Pokaż listę
                </button>
            </div>
            <div 
                className='dane'
            >
                {add ? (
                    <MieszkancyAddForm
                        setAdd={setAdd}
                        idw={id_wspolnoty}
                    />
                ) : (
                    <MieszkancyLista
                        wspolnota={wspolnota}
                        mieszkancy_w={mieszkancy_w}
                        mieszkaniec={mieszkaniec}
                        setMieszkaniec={setMieszkaniec}
                        setAdd={setAdd}
                        setEdit={setEdit}
                        udzialyKontaAkt_w={udzialyKontaAkt_w}
                        grupyAkt_w={grupyAkt_w}
                        setEditDataOd={setEditDataOd}
                        setEditDataDo={setEditDataDo}
                    />
                )} 
                {
                    mieszkaniec ? (
                        <MieszkancyDane
                            mieszkaniec={mieszkaniec}
                            mieszkaniecAll={mieszkaniecAll}
                            wspolnota={wspolnota}
                            id_wspolnoty={id_wspolnoty}
                            add={add}
                            edit={edit}
                            setEdit={setEdit}
                            udzialyKonta_w={udzialyKonta_w}
                            udzialyKontaAkt_w={udzialyKontaAkt_w}
                            grupyAkt_w={grupyAkt_w}
                            tablicaPrzelewy_u={tablicaPrzelewy_u}
                            editDataOd={editDataOd}
                            editDataDo={editDataDo}
                            setEditDataOd={setEditDataOd}
                            tablicaCzynsze={tablicaCzynsze}
                            setEditDataDo={setEditDataDo}
                        />
                    ) : ""
                }
            </div>
            <Loader
                stan={loaderOn}
            />
        </div>
    )
}

export default Mieszkancy;