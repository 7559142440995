import React, { useState, useContext} from "react";
// import { motion } from 'framer-motion' 
import axios from "axios";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { faAccusoft } from '@fortawesome/free-brands-svg-icons';
import { faDoorClosed, faPencil, faChevronLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import { dataFormat } from "../../functions/dataFormat";
import { fetchGrupy, fetchGrupyAkt, fetchGrupyAll } from "../../Redux/Actions";
import { AuthContext } from "../../Context/AuthProvider";

const MieszkancyDaneGrupyLokale = props => {

    const { role} = useContext(AuthContext);

    const { tytul, obj, editDataOd, setEditDataOd, editDataDo, setEditDataDo } = props;
    
    const dispatch = useDispatch();

    const ikona = (tytul) => {
        switch (tytul){
            case "Mieszkanie": 
                return (<FontAwesomeIcon icon={faHouse} />)
            case "Garaże":
                return (<FontAwesomeIcon icon={faWarehouse} />);
            case "Komórki":
                return (<FontAwesomeIcon icon={faDoorClosed} />);
            case "Strychy":
                return (<FontAwesomeIcon icon={faAccusoft} />);
            default:
                return "";
        }
    }

    const handleDataOd = e => setEditDataOd( prevState =>({...prevState, dataOd: e.target.value} ));
    const handleDataDo = e => setEditDataDo( prevState =>({...prevState, dataDo: e.target.value} ));

    const handlePencilOd = id => {
        setEditDataOd({
            show: true,
            id: id,
            dataOd: ""
        })
    }

    const handlePencilDo = id => {
        setEditDataDo({
            show: true,
            id: id,
            dataDo: ""
        })
    }

    const saveOd = async () => {
        try {
            await axios.post('/api/grupy/edytuj_data_od', {
                data_od: editDataOd.dataOd, 
                id: editDataOd.id
            }).then( response => {
                dispatch(fetchGrupy());
                dispatch(fetchGrupyAkt());
                dispatch(fetchGrupyAll());
                setEditDataOd({id: 0, show: false, dataOd: ""});
            })
        } catch (error) {
            console.log(error);
        }
    }

    const handleSaveDataOd = id => {
        if (editDataOd.dataOd === "")
            alert('Podaj datę');
        else (
            saveOd()
        )
    }

    const handleSaveDataDo = id => {
        if (editDataDo.dataDo === "")
            alert('Podaj datę');
        else (
            saveDo()
        )
    }

    const saveDo = async () => {
        try {
            await axios.post('/api/grupy/edytuj_data_do', {
                data_od: editDataDo.dataDo, 
                id: editDataDo.id
            }).then( response => {
                dispatch(fetchGrupy());
                dispatch(fetchGrupyAkt());
                dispatch(fetchGrupyAll());
                setEditDataDo({id: 0, show: false, dataDo: ""});
            })
        } catch (error) {
            console.log(error);
        }
    }

    const handleAnulujOd = id => setEditDataOd({id: 0, show: false, dataOd: ""});
    const handleAnulujDo = id => setEditDataDo({id: 0, show: false, dataDo: ""});

    const lista = obj.map( (el,i) => {
        console.log(el)
        return (
            <div key={i} className="lokal">
                <ul className='lokale lista'>
                    <li>
                        <div>{`Adres:`}</div>
                        <div>{`${el?.ulica?.rodzaj} ${el?.ulica?.nazwa} ${el?.ulica?.numer_budynku} / ${el?.lokal} ${el?.klatka}`}</div>
                    </li>
                    <li>
                        <div>Powierzchnia:</div>
                        <div>{`${el?.metraz} m²`}</div>
                    </li>
                    <li>
                        <div>Udział:</div>
                        <div>{`${el?.udzial !== null ? el?.udzial : "brak danych" }`}</div>
                    </li>
                    {
                        tytul === "Mieszkanie" ? (
                            <li>
                                <div>Liczba mieszkańców:</div>
                                <div>{el?.liczba_domownikow}</div>
                            </li>
                        ) : ""
                    }
                    {
                        role === "admin" ? (
                            editDataOd?.show && editDataOd?.id == el.grupa_id ? (
                                <div className="edit-data-od">
                                    <input 
                                        type="date"
                                        value={editDataOd?.dataOd}
                                        onChange={handleDataOd}
                                    />
                                    <FontAwesomeIcon 
                                        icon={faChevronLeft} 
                                        onClick={()=>handleAnulujOd()}
                                        className="pointer"
                                    />
                                    <FontAwesomeIcon 
                                        icon={faCheck} 
                                        onClick={()=>handleSaveDataOd(el.grupa_id)}
                                        className="pointer"
                                    />
                                </div>
    
                            ) : (
                                <li>
                                    <div>Czynsz liczony od:</div>
                                    <div style={{fontWeight: 600}}>{dataFormat(el?.data_od)}</div>
                                    <div style={{marginLeft: "20px"}}
                                    >
                                        <FontAwesomeIcon 
                                            icon={faPencil} 
                                            onClick={()=>handlePencilOd(el.grupa_id)}    
                                            className="pointer"
                                        />
    
                                    </div>
                                </li>
                            )
                        ) : (
                            <></>
                        )
                    }
                    {
                        role === "admin" && el.data_do !== null ? (
                            editDataDo?.show && editDataDo?.id == el.grupa_id ? (
                                <div className="edit-data-od">
                                    <input 
                                        type="date"
                                        value={editDataDo?.dataDo}
                                        onChange={handleDataDo}
                                    />
                                    <FontAwesomeIcon 
                                        icon={faChevronLeft} 
                                        onClick={()=>handleAnulujDo()}
                                        className="pointer"
                                    />
                                    <FontAwesomeIcon 
                                        icon={faCheck} 
                                        onClick={()=>handleSaveDataDo(el.grupa_id)}
                                        className="pointer"
                                    />
                                </div>
    
                            ) : (
                                <li>
                                    <div>Czynsz liczony do:</div>
                                    <div style={{fontWeight: 600}}>{dataFormat(el?.data_do)}</div>
                                    <div style={{marginLeft: "20px"}}
                                    >
                                        <FontAwesomeIcon 
                                            icon={faPencil} 
                                            onClick={()=>handlePencilDo(el.grupa_id)}    
                                            className="pointer"
                                        />
    
                                    </div>
                                </li>
                            )
                        ) : (
                            <></>
                        )
                    }
                </ul>
            </div>
        )
    });

    const liczbaLokali = tytul === "Mieszkanie" ? (` ${tytul}`) : (` ${tytul} (${obj?.length})`);

    return (
        <div className="lokale">
            <h4>{ikona(tytul)} {liczbaLokali} </h4>
            { lista }
        </div>
    )
}

export default MieszkancyDaneGrupyLokale;