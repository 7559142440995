import React from "react";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareFacebook, faYoutube, faSquareInstagram } from '@fortawesome/free-brands-svg-icons'
import logo from '../images/Biuro  obsługi nieruchomości_Mojsiewicz&Stanek_logo ok_przezroczyste.png'
import em3 from '../images/em3_s.png'
import '../scss/style.css';
import '../scss/footer.css';

const Footer = () => {
    const navigate = useNavigate();

    const clickLogo = () => navigate(`/`);
    const handlePolityka = () => navigate(`/polityka-prywatnosci`);

    return (
        <div className="content">
            <div className="contact">
                <div className="c1">
                    <div className="logo pointer" style={{margin: "0 0 10px 0"}} onClick={clickLogo}>
                        <img src={logo}  alt="logo"></img>
                    </div>
                    <div className="logo pointer" onClick={clickLogo}>
                        <img src={em3}  alt="logo"></img>
                    </div>
                    <div className="socials">
                        <a href="https://www.facebook.com/profile.php?id=61562412718700" target="_blank">
                            <FontAwesomeIcon icon={faSquareFacebook} className="pointer" size="2xl"/>
                        </a>
                        <a href="https://www.instagram.com/bon.administrator?igsh=MXNkaG51bDFvMWxoOQ%3D%3D" target="_blank">
                            <FontAwesomeIcon icon={faSquareInstagram} className="pointer" size="2xl" />
                        </a>
                    </div>
                </div>
                <div className="c2">
                    <p className="head">godziny otwarcia</p>
                    <div> <p>Poniedziałek:</p> <p>08:00-16:00</p>  </div>
                    <div> <p>Wtorek:</p> <p>08:00-16:00</p>  </div>
                    <div> <p>Środa:</p> <p>08:00-16:00</p>  </div>
                    <div> <p>Czwartek:</p> <p>08:00-16:00</p>  </div>
                    <div> <p>Piątek:</p> <p>08:00-14:00</p>  </div>
                </div>
                <div className="c3">
                    <p className="head">Przydatne telefony</p>
                    <div>   <p>Tel. alarmowy:</p>  <p>112</p>  </div>
                    <div>   <p>Policja:</p> <p>997</p>       </div>
                    <div>   <p>Straż Pożarna:</p> <p>998</p>       </div>
                    <div>   <p>Pogotowie Ratunkowe:</p> <p>999</p>       </div>
                    <div>   <p>Pogotowie energetyczne:</p> <p>991</p>       </div>
                    <div>   <p>Pogotowie gazowe:</p> <p>992</p>       </div>
                    <div>   <p>Pogotowie ciepłownicze:</p> <p>993</p>       </div>
                    <div>   <p>Pogotowie wodociągowe:</p> <p>95 72 85 951</p>       </div>
                    <div>   <p>Straż Miejska:</p> <p>95 735  5728</p>       </div>
                </div>
                <div className="c4">
                    <p className="head">dane kontaktowe</p>

                    <p className="brand">Biuro Obsługi Nieruchomości</p>
                    <p className="brand">Mojsiewicz & Stanek s.c.</p>
                    <div>
                        <p>ul. Mieszka I 57/6</p>
                        <p>66-400 Gorzów Wielkopolski</p>
                        <p>NIP 599-327-93-68</p>
                    </div>
                    <div>
                        <p><strong>tel:</strong> 690 240 779</p>
                        <p><strong>tel:</strong> 95 724 19 08</p>
                        <p><strong>email:</strong> biuro@bonadministrator.pl </p>
                    </div>
                    <div>
                        <p className="pointer" onClick={()=>{handlePolityka()}}>
                            <strong>Polityka prywatności</strong>
                        </p>
                    </div>
                </div>
            </div>
            <div className="singature">
                <p>Projekt i wykonanie</p>
                <p>Pentakilo sp. z o. o.</p>
            </div>
        </div>
    )
}

export default Footer;