import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MieszkancyDaneGrupy from './MieszkancyDaneGrupy'
import MieszkancyDaneEdytujGrupe from './EdytujGrupe/MieszkancyDaneEdytujGrupe.js';
import MieszkancyDaneDodajGrupe from './MieszkancyDaneDodajGrupe';
import MieszkancyDaneHistoriaZmian from './MieszkancyDaneHistoriaZmian';
import MieszkancyDaneInformacjeWsad from './MieszkancyDaneInformacjeWsad.js';

import { 
    fetchGrupy, 
    fetchGrupyAll,
    fetchUdzialyKonta,
    fetchGrupyAkt,
    fetchWersje,    
  } from '../../Redux/Actions.js'; 

const MieszkancyDaneInformacje = props => {

    const { mieszkaniec,tablicaCzynsze, mieszkaniecAll, id_wspolnoty, dodajGrupe, setDodajGrupe, udzialyKonta_w, 
        udzialyKontaAkt_w, wspolnota, tablicaPrzelewy_u, editDataOd, editDataDo, setEditDataOd, setEditDataDo } = props;

    const [ulice_w, setUlice_w] = useState([]);
    const [lokale_w, setLokale_w] = useState([]);   
    const [editGrupe, setEditGrupe] = useState(0);

    const [grupy_u, setGrupy_u] = useState([]);
    const [grupyAkt_u, setGrupyAkt_u] = useState([]);
    const [udzialyKontaAkt_u, setUdzialyKontaAkt_u] = useState([]);
    const [udzialyKonta_u, setUdzialyKonta_u] = useState([]);
    const [grupyAll_u, setGrupyAll_u] = useState([]);
    const [user, setUser] = useState([]);
    const [dodajGrupeDla, setDodajGrupeDla] = useState(undefined);
    const [ktoraGrupa, setKtoraGrupa] = useState(1);

    const dispatch = useDispatch();

    const [zmianyMieszkancaPokaz, setZmianyMieszkancaPokaz] = useState( false )

    const lokale = useSelector( state => state?.lokale?.data);
    const ulice = useSelector( state => state?.ulice?.data);
    const grupy = useSelector( state => state?.grupy?.data);
    const grupyAll = useSelector( state => state?.grupyAll?.data);
    const grupyAkt = useSelector( state => state?.grupyAkt?.data);
    const przelewy = useSelector(state=> state?.przelewy?.data);
    const users = useSelector( state => state?.users?.data);

    useEffect( () => {
        dispatch(fetchGrupy());
        // dispatch(fetchUdzialyKonta());
    },[dodajGrupeDla, editGrupe, mieszkaniec]);

    useEffect( () => {
        dispatch(fetchWersje(grupy));
    },[grupy, mieszkaniec]);

    useEffect( () => dispatch(fetchGrupyAll()), [dispatch]);

    useEffect( () => setEditGrupe(0), [mieszkaniec])

    useEffect( () => setLokale_w( lokale?.filter( l => l?.id_wspolnoty == id_wspolnoty ) ), [lokale]);

    useEffect( () => setUlice_w( ulice?.filter( u => u?.id_wspolnoty == id_wspolnoty) ), [ulice]);

    useEffect( () => setGrupy_u(grupy?.filter( g => g.id_user == mieszkaniec?.id_user )), [grupy, mieszkaniec]);
    
    useEffect( () => setGrupyAkt_u( grupyAkt?.filter( g => g.id_user == mieszkaniec?.id_user) ), [grupyAkt, mieszkaniec]);

    useEffect( () => setUser( users?.find( u => u.id == mieszkaniec?.id_user)),[mieszkaniec, users]);

    useEffect( () => setUdzialyKontaAkt_u( udzialyKontaAkt_w?.filter( u => u.id_user == mieszkaniec?.id_user) ), [udzialyKontaAkt_w, editGrupe, mieszkaniec]);

    useEffect( () => setUdzialyKonta_u( udzialyKonta_w?.filter( u => u.id_user == mieszkaniec?.id_user)), [udzialyKonta_w, mieszkaniec]);

    useEffect( () => setGrupyAll_u( grupyAll?.filter( g => g.id_user == mieszkaniec?.id_user)), [grupyAll, mieszkaniec]);

    useEffect( () => {
        if (udzialyKontaAkt_u)  
            setKtoraGrupa( udzialyKontaAkt_u?.length + 1 );
    },[udzialyKontaAkt_u]);

    return (
        <div className="mieszkancyDaneContainer">

            <div className="lista ramka">
                <h2>
                    Informacje podstawowe
                </h2>
                <MieszkancyDaneInformacjeWsad 
                    mieszkaniec={mieszkaniec}
                    user={user}
                />
                <MieszkancyDaneHistoriaZmian 
                    mieszkaniecAll={mieszkaniecAll}
                    zmianyMieszkancaPokaz={zmianyMieszkancaPokaz}
                    setZmianyMieszkancaPokaz={setZmianyMieszkancaPokaz}
                />
                {
                    dodajGrupe && (
                        <MieszkancyDaneDodajGrupe 
                            mieszkaniec={mieszkaniec} 
                            id_wspolnoty={id_wspolnoty}
                            setDodajGrupe={setDodajGrupe}
                            ktoraGrupa={ktoraGrupa}
                            wspolnota={wspolnota}
                        />
                    )
                }
            </div>
            {
                editGrupe === 0 ? (
                    <div className='grupy'>
                        <h3>
                            { udzialyKontaAkt_u?.length > 1 ? `Informacje o grupach czynszowych` : `Informacje o grupie czynszowej` }
                        </h3>
                        <MieszkancyDaneGrupy 
                            mieszkaniec={mieszkaniec} 
                            editGrupe={editGrupe}
                            setEditGrupe={setEditGrupe} 
                            grupy_u={grupy_u}
                            grupyAkt_u={grupyAkt_u}
                            grupyAll_u={grupyAll_u}
                            udzialyKontaAkt_u={udzialyKontaAkt_u}
                            udzialyKonta_u={udzialyKonta_u}
                            przelewy={przelewy}
                            wspolnota={wspolnota}
                            tablicaPrzelewy_u={tablicaPrzelewy_u}
                            editDataOd={editDataOd}
                            setEditDataOd={setEditDataOd}
                            editDataDo={editDataDo}
                            setEditDataDo={setEditDataDo}
                            tablicaCzynsze={tablicaCzynsze}
                        />
                    </div>
                ) : (
                    <div className='grupy'>
                        <h3>
                            Edycja grupy
                        </h3>
                        <MieszkancyDaneEdytujGrupe 
                            mieszkaniec={mieszkaniec} 
                            editGrupe={editGrupe}
                            setEditGrupe={setEditGrupe}
                            lokale={lokale_w}
                            ulice={ulice_w}
                            grupy_u={grupy_u}
                            udzialyKontaAkt_u={udzialyKontaAkt_u}
                            editDataOd={editDataOd}
                            setEditDataOd={setEditDataOd}
                            editDataDo={editDataDo}
                            setEditDataDo={setEditDataDo}
                            wspolnota={wspolnota}
                        />
                    </div>
                )
            }
        </div>
    )
}

export default MieszkancyDaneInformacje;